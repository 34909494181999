<template>
  <section data-testid="cart-item">
    <BaseAlert v-if="itemError" variant="danger">
      {{ $label(`cart.checkout.${itemError}`) }}
    </BaseAlert>

    <BaseSpinner v-if="isLoading" />

    <div :class="['row g-0 w-100', { 'book--loading': isLoading }]">
      <div
        :class="[
          'col-3 book__cover d-flex align-items-center justify-content-center h-100',
          checkoutVariant ? 'col-md-2' : 'col-md-3',
        ]"
      >
        <component :is="wrappingComponent" :to="bookLink">
          <BookCover
            :title="item.title"
            :cover="item.cover"
            :width="82.5"
            :height="126.75"
          />
        </component>
      </div>
      <div
        :class="[
          'col-4 align-self-center ps-3',
          checkoutVariant ? 'col-md-6' : 'col-md-5',
        ]"
      >
        <component :is="wrappingComponent" :to="bookLink" class="book__info">
          <small class="text-muted text-lowercase">
            {{ $label('common.ebook') }}
          </small>
          <h4 class="book__title fz-14">
            {{ item.title }}
          </h4>
          <p v-if="item.author" class="book__author text-muted caption">
            {{ item.author.name || item.author }}
          </p>
        </component>
      </div>
      <div
        cols="5"
        md="4"
        class="col-5 col-md-4 d-flex flex-column justify-content-between align-items-end"
      >
        <div>
          <BaseButton
            v-if="showDeleteBtn || itemError"
            variant="naked"
            class="cart__delete-btn"
            @click="deleteItem"
          >
            <TrashIcon class="trash-icon" />
          </BaseButton>
        </div>
        <div class="float-right">
          <BasePrice
            discount-name-type="order-summary-monthly-price"
            :original-price="item.listPrice"
            :discount-price="item.finalPrice"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { TRACKING_LISTS } from '~/utils/constants'
import BasePrice from '~/components/BasePrice'
import BookCover from '~/components/books/BookCover'
import BaseAlert from '~/components/alerts/BaseAlert'
import TrashIcon from '~/assets/icons/trash.svg'
import { NuxtLink } from '#components'

export default {
  components: {
    BasePrice,
    BookCover,
    BaseAlert,
    TrashIcon,
    NuxtLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    cartUid: {
      type: String,
      default: '',
    },
    productUid: {
      type: String,
      default: '',
    },
    checkoutVariant: {
      type: Boolean,
      default: false,
    },
    showDeleteBtn: {
      type: Boolean,
      default: true,
    },
    itemError: {
      type: String,
      default: '',
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      removingItem: 'shopCart/removingItem',
    }),
    wrappingComponent() {
      return this.clickable ? 'NuxtLink' : 'div'
    },
    bookLink() {
      return `/shop/boek/${this.item.slug}`
    },
  },
  methods: {
    ...mapActions({
      removeFromCart: 'shopCart/removeFromCart',
    }),
    async deleteItem() {
      try {
        if (this.removingItem) return

        this.isLoading = true

        await this.removeFromCart({
          cartUid: this.cartUid,
          productUid: this.productUid,
        })

        this.sendTracking()
        this.onDelete()
      } catch (error) {
        this.$sentryCaptureException(error)
        this.$toast.error(this.$label('messages.errorOccuredMessage'))
      } finally {
        this.isLoading = false
      }
    },
    sendTracking() {
      const item = {
        id: this.productUid,
        name: this.item.title,
        price: this.item.listPrice,
        discount: this.item.listPrice - this.item.finalPrice,
        ...TRACKING_LISTS.shop,
      }

      this.$gtmTracking.removeFromCart(item, this.item.finalPrice)
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-border {
  position: absolute;
  left: 50%;
  top: 40%;
}

.book--loading {
  opacity: 0.5;
}

.book__info {
  text-decoration: none;
}

.book__title {
  margin-bottom: 3px;
}

.sale-price {
  font-size: 12px;
}

.cart__delete-btn {
  padding: 0 0 12px 12px;
  min-width: unset;
}

.trash-icon {
  fill: $mid-grey;
  height: 22px;
  width: 22px;
}
</style>
